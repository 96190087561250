<template>
  <el-tooltip
      :content="text"
      raw-content
      placement="top"
      effect="light"
  >
    <span v-bind="attrs" class="text" v-html="text"></span>
  </el-tooltip>
</template>

<script setup>
import {toRefs, useAttrs} from "vue";

const attrs = useAttrs()
const props = defineProps({
  text: {
    type: String,
    default: ''
  },
})
const {text} = toRefs(props)
</script>
<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: 'ShowMore',
})
</script>

<style lang="scss" scoped>
.text {
  @include textOverflow(60%)
}
</style>