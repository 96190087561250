import {ref} from "vue";

export default function () {
    const showModal = ref(false);

    const openModal = () => {
        showModal.value = true;
    }

    const handleOpenModal = (callBack) => {
        if (typeof callBack === 'function') {
            callBack(openModal)
            return;
        }
        openModal()
    }

    const closeModal = () => {
        showModal.value = false;
    }
    const handleCloseModal = (callBack) => {
        if (typeof callBack === 'function') {
            callBack(closeModal)
            return;
        }
        closeModal()
    }

    return {
        showModal,
        handleOpenModal,
        handleCloseModal
    }
}