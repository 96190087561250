<!--
 * @Author: wangxinlie 278019672@qq.com
 * @Date: 2023-07-23 19:02:13
 * @LastEditors: wangxinlie 278019672@qq.com
 * @LastEditTime: 2023-08-01 10:24:02
 * @FilePath: /aurora-carton-web/src/components/moreList/moreList.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="moreList cur_p" v-if="localList">
    <el-popover
      width="250"
      placement="bottom"
      trigger="hover"
      transition="el-zoom-in-top"
      v-if="localList && localList.length"
    >
      <template #reference>
        <div class="desc inline">
          <div class="text" v-bind="$attrs">{{
              localList[0]?.modelId || localList[0]?.modelName ? localList[0]?.modelName : localList[0]
            }}
          </div>
          <el-icon class="icon"><ArrowDown /></el-icon>
        </div>
      </template>
      <div class="encodedList">
        <div class="single" v-for="item in localList" :key="item" v-bind="$attrs">
          {{ item?.modelId || item?.modelName ? item.modelName : item }}
          </div>
      </div>
    </el-popover>
  </div>
  <div v-bind="$attrs" v-else>--</div>
</template>
<script setup>
import {ArrowDown} from "@element-plus/icons-vue";
import {defineProps, watch, toRefs ,ref} from "vue";

const localList = ref([]);
const props = defineProps({
  list: {
    type: [Array, String],
    default: () => [],
  },
});
const { list } = toRefs(props);
function setList (){
  if (typeof list.value === "string") {
    if (list.value.indexOf("/") !== -1) {
      localList.value = list.value.split("/");
    } else if (list.value.indexOf(",") !== -1) {
      localList.value = list.value.split(",");
      }else{
        localList.value = [list.value];
      }
  }else if(Array.isArray(list.value)){
    localList.value = list.value.filter(item => !item.discard);
  }
}
watch(list, (newVal) => {
  setList()
},{
  immediate: true,
})
</script>

<script>
import { ArrowDown } from "@element-plus/icons-vue";
import { reactive, toRefs } from "vue";
export default {
  name: "moreList",
};
</script>

<style lang="scss">
.moreList{
  font-size: 14px;
  .zh-value{
    color: $fsColor;
  }
  .desc {
  color: #737480;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1.3;
  &.one {
    width: auto;
     @include textOverflow(60px);
    display: inline-block;
    line-height: 1.3;
  }
  .text {
    display: inline-block;
    @include textOverflow(100px);
    line-height: 1.3;
  }
  .icon {
    vertical-align: middle;
  }
}
}

</style>
